
import download from './assets/white.svg';

function HomePage() {
  // Generate URL that includes a trigger parameter
 
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        padding: "0 1.25rem",
      }}
    >
         <a 
        
        href="https://apps.apple.com/us/app/nuun-live-events/id6736663966?itscg=30200&itsct=apps_box_link&mttnsubad=6736663966" // Replace with your App Store link
        target="_blank" 
        rel="noopener noreferrer"
      >
        <img 
          src={download} 
          alt="Download App" 
          style={{
            width: '200px', // Adjust size as needed
            height: '100px',
            marginTop: '3.125rem', // Adjust margin as needed
          }} 
        />
      </a>

      
    </div>
  );
}

export default HomePage;
