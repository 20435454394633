import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { TextField } from "@mui/material";
import { db } from "./firebase";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function Events() {
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEvents, setFilteredEvents] = useState([]);
  let navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = collection(db, "events1");
      const eventsSnapshot = await getDocs(eventsCollection);
      const eventsList = eventsSnapshot.docs.map((doc, index) => {
        const data = doc.data();
        const date = data.date?.toDate();

        return {
          id: index + 1,
          title: data.title,
          artist: data.artist,
          city: data.city,
          dateObj: date || new Date(0),
          date: date ? date.toLocaleString() : "No date",
          category: data.category,
          docId: doc.id,
        };
      });
      setEvents(eventsList);
      setFilteredEvents(eventsList);
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    const filtered = events.filter((event) => {
      return Object.values(event).some((value) =>
        String(value).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredEvents(filtered);
  }, [searchTerm, events]);

  const handleEdit = (event) => {
    navigate(`/events/edit-event/${event.docId}`);
  };

  const handleDelete = async (event) => {
    // Handle the delete action
    console.log(`Deleting event: ${event.docId}`);
    const eventDoc = doc(db, "events1", event.docId);
    await deleteDoc(eventDoc);
    setEvents(events.filter((e) => e.docId !== event.docId));
  };

  const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 200,
      sortComparator: (v1, v2, params1, params2) =>
        params1.api.getCellValue(params1.id, "dateObj") -
        params2.api.getCellValue(params2.id, "dateObj"),
    },
    { field: "artist", headerName: "Artist", width: 200 },
    { field: "title", headerName: "Title", width: 200 },
    { field: "city", headerName: "City", width: 200 },

    { field: "category", headerName: "Category", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => (
        <>
          <button
            style={{ marginRight: "10px", padding: "5px" }}
            onClick={() => handleEdit(params.row)}
          >
            Edit
          </button>
          <button
            style={{ padding: "5px" }}
            onClick={() => handleDelete(params.row)}
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          marginTop: "10vh",
          height: "80vh",
          width: "100%",
          padding: "50px",
        }}
      >
        <TextField
          label="Search Events"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ maxWidth: "100", marginBottom: "20px", width: "100%" }}
        />
        <DataGrid
          rows={filteredEvents}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
        />
      </div>
    </>
  );
}

export default Events;
